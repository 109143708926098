.s5j1a9j{background-color:var(--primary-color);box-shadow:0 0 4px var(--secondary-color);z-index:2;}
.swfhi46{color:inherit;}.swfhi46+.swfhi46::before{content:'';width:40px;height:1px;background:var(--primary-color);margin:var(--space-m) auto;display:block;}
.s18s47e{color:inherit;font-family:'Roboto';font-weight:400;font-size:var(--step-0);-webkit-text-decoration:none;text-decoration:none;}.s18s47e:hover{-webkit-filter:brightness(0.8);filter:brightness(0.8);}
.sdq706u{position:absolute;left:0;top:0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;list-style:none;width:100vw;height:100vh;color:var(--primary-color);margin:0;padding:0;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:var(--secondary-color);-webkit-transition:opacity 0.3s, visibility 0.3s;transition:opacity 0.3s, visibility 0.3s;opacity:0;visibility:hidden;z-index:99;}.sdq706u[data-open="true"]{opacity:1;visibility:visible;}
.s1o5xaci{font-family:'Bad Script';font-size:var(--step-1);margin-right:var(--space-xs);}
.s5wnqo1{font-family:'Roboto';font-size:var(--step-0);font-weight:100;}
.s1opljcy.s5wnqo1{margin-right:var(--space-xs);}
.sq5yrcv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;color:inherit;-webkit-text-decoration:none;text-decoration:none;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:var(--space-s);width:100%;}
.s1yyr3xl{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-align-items:baseline;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline;}
.s1lxmpda{margin:var(--space-2xs) var(--space-s);}
.s1flmpn3{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;max-width:67.5rem;height:100%;margin:0 auto;}
.ma6ilul{border:none;background:none;z-index:100;cursor:pointer;}.ma6ilul i+i{margin-top:var(--menu-icon-space);}.ma6ilul:hover i{-webkit-filter:brightness(3);filter:brightness(3);}.ma6ilul:hover i[data-open="true"]{-webkit-filter:brightness(0.6);filter:brightness(0.6);}
.s1hvudtw{max-height:2rem;}
.s1ci5d9m{display:block;width:var(--menu-icon-width);height:var(--menu-icon-height);background-color:var(--secondary-color);}.s1ci5d9m[data-open="true"]{background-color:var(--primary-color);}
